import axios from "axios";

export const signUpUser = async (userValue, account_type) => {
  try {
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    // Update user profile
    const res = await axios.post("/update_profile", { is_individual: account_type, user: {...userValue, accounttype: account_type} });
    if (!(res.status > 200 && res.status < 300)) return "Failed to signup.";
    if (res.data.return_to_pickup) window.location = "/send_package/pickup";
    else window.location = "/";
  } catch (err) {
    console.log(err);
    return "Failed to signup.";
  }
};
