import React, { Component } from "react";
import BusinessStep from "./BusinessStep";
import AddressStep from "./AddressStep";
import FinalStep from "./FinalStep";

export class IndividualSignUpPage extends Component {
  state = {
    step: 1,
    business_name: "",
    business_email: "",
    registration_number: "",
    vat_number: "",
    phone: null,
    street: "",
    unit: "",
    country: "",
    city: "",
    state: "",
    postalcode: "",
    facebook: "",
    instagram: "",
    errors: {},
    isContinue: 0,
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    console.log(input, e, { [input]: e.target.value });
    this.setState({ [input]: e.target.value });
  };

  handleChangeManual = (name, value) => {
    this.setState({ [name]: value });
  };

  setErrors = (newErrors) => {
    this.setState({
      errors: newErrors,
    });
  };

  setIsContinue = (newIsContinue) => {
    this.setState({
      isContinue: newIsContinue,
    });
  };

  render() {
    const { step } = this.state;
    const {
      business_name,
      business_email,
      registration_number,
      phone,
      vat_number,
      street,
      unit,
      country,
      city,
      state,
      postalcode,
      facebook,
      instagram,
      errors,
      isContinue,
    } = this.state;
    const values = {
      business_name,
      business_email,
      registration_number,
      phone,
      vat_number,
      street,
      unit,
      country,
      city,
      state,
      postalcode,
      facebook,
      instagram,
    };

    switch (step) {
      case 1:
        return (
          <div class="container-signup-individual">
            <div class="sub-container-signup-individual">
              <h1 class="create-an-account">Create a Business Account</h1>
              <p class="title-personal-info">Provide your business details</p>
              <p class="title-personal-info">Provide your business details</p>
              <div className="step-1-status-signin-individual">
                <div>1. Business</div>
                <div>2. Address</div>
                <div>3. Final Step</div>
              </div>
              {Object.values(errors).map((error) => (
                <>
                  <p class="title-personal-info" style={{ color: "red", marginTop: "1rem", marginBottom: "0px" }}>
                    {error}
                  </p>
                </>
              ))}
              <BusinessStep
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                setErrors={this.setErrors}
                values={values}
              />

              <p class="account-exists">
                Already have an account?{" "}
                <span>
                  <a href="/sign_in">Sign in</a>
                </span>
              </p>
            </div>
          </div>
        );
      case 2:
        return (
          <div class="container-signup-individual">
            <div class="sub-container-signup-individual">
              <h1 class="create-an-account">Create an Account</h1>
              <p class="title-personal-info">
                Provide your personal information
              </p>
              <div className="step-2-status-signin-individual">
                <div>1. Business</div>
                <div>2. Address</div>
                <div>3. Final Step</div>
              </div>
              <AddressStep
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                handleChangeManual={this.handleChangeManual}
                values={values}
                formErrors={errors}
                setErrors={this.setErrors}
                isContinue={isContinue}
                setIsContinue={this.setIsContinue}
              />
              <p class="account-exists">
                Already have an account?{" "}
                <span>
                  <a href="/sign_in">Sign in</a>
                </span>
              </p>
            </div>
          </div>
        );
      case 3:
        return (
          <div class="container-signup-individual">
            <div class="sub-container-signup-individual">
              <h1 class="create-an-account">Create an Account</h1>
              <p class="title-personal-info">
                Provide your personal information
              </p>
              <div className="step-3-status-signin-individual">
                <div>1. Business</div>
                <div>2. Address</div>
                <div>3. Final Step</div>
              </div>
              <FinalStep
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                prevStep={this.prevStep}
                values={values}
              />
              <p class="account-exists">
                Already have an account?{" "}
                <span>
                  <a href="/sign_in">Sign in</a>
                </span>
              </p>
            </div>
          </div>
        );
    }
  }
}

export default IndividualSignUpPage;
