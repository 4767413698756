import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  create_individual_user_path,
  new_user_registration_path,
  random_path,
} from "../../../routes";
import { signUpUser } from "../sign_up_util";

function FinalStep({
  handleChange,
  formErrors,
  setErrors,
  values,
  isContinue,
  setIsContinue,
  prevStep
}) {
  const [error, setError] = useState(undefined);
  const continues = (e) => {
    e.preventDefault();
    setIsContinue(3);
    setErrors(validate());
  };

  const back = (e) => {
    e.preventDefault();
    setIsContinue(1);
    prevStep();
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isContinue == 3) {
      const error = signUpUser(values, "0");
      if (error) setError(error);
    }
  }, [formErrors]);


  const validate = () => {
    const errors = {};

    return errors;
  };

  return (
    <div className="form-signup-individual">
      <label className="label-signup-individual">Firstname</label>
      <h4 className="signup-individual-confirm-text">{values.firstname}</h4>
      <label className="label-signup-individual">Surname</label>
      <h4 className="signup-individual-confirm-text">{values.surname}</h4>
      <label className="label-signup-individual">Phone</label>
      <h4 className="signup-individual-confirm-text">{values.phone}</h4>
      <label className="label-signup-individual">Street</label>
      <h4 className="signup-individual-confirm-text">{values.street}</h4>
      <label className="label-signup-individual">Unit</label>
      <h4 className="signup-individual-confirm-text">{values.unit}</h4>
      <label className="label-signup-individual">Country</label>
      <h4 className="signup-individual-confirm-text">{values.country}</h4>
      <label className="label-signup-individual">City</label>
      <h4 className="signup-individual-confirm-text">{values.city}</h4>
      <label className="label-signup-individual">State</label>
      <h4 className="signup-individual-confirm-text">{values.state}</h4>
      <label className="label-signup-individual">Postal Code</label>
      <h4 className="signup-individual-confirm-text">{values.postalcode}</h4>
      <div className="div-buttons-signin-individual">
        <button onClick={back} className="back-button-signup-individual">
          Back
        </button>
        <button
          onClick={(e) => continues(e)}
          className="next-button-signup-individual"
        >
          Finish
        </button>
      </div>
    </div>
  );
}

export default FinalStep;
