import React, { useState } from "react";
import styled, { css } from "styled-components";

const formatDate = (date) => {
  const dateToFormat = new Date(date);
  return `${dateToFormat.getFullYear()}-${dateToFormat.getMonth()}-${dateToFormat.getDate()}`;
};

const OrderStatus = ({ orderStatus }) => {
  const orderStatusValue = orderStatus ? orderStatus : "error";
  return (
    <OrderStatusStyled
      $background_color={orderStatusValue === "error" ? "red" : "#073B62"}
    >
      <OrderStatusText>{orderStatusValue}</OrderStatusText>
    </OrderStatusStyled>
  );
};

const BasicValueDisplay = ({ label, value, className }) => {
  return (
    <BasicValueDisplayContainer className={className}>
      <BasicValueDisplayLabel>{label}</BasicValueDisplayLabel>
      <BasicValueDisplayValue>{value}</BasicValueDisplayValue>
    </BasicValueDisplayContainer>
  );
};

const Order = ({ order, translations }) => {
  return (
    <OrderComponent>
      <OrderContent>
        <OrderDetails>
          <OrderNumberAndDateContainer>
            <OrderDetailsBasicValue
              label={translations.order_number}
              value={`#${order.viva_wallet_order_id}`}
            />
            <OrderDetailsBasicValue
              label={translations.order_date}
              value={formatDate(order.created_at)}
            />
          </OrderNumberAndDateContainer>
          <OrderStatusLabel>{translations.order_status}</OrderStatusLabel>
          <OrderStatus orderStatus={order.status} />
        </OrderDetails>
        <SeparatorDiv />
        <AddressDisplay>
          <BasicValueDisplay
            label={translations.pick_up_address}
            value={order.package_order_data.pickup_address}
          />
          <BasicValueDisplay
            label={translations.delivery_address}
            value={order.package_order_data.delivery_address}
          />
        </AddressDisplay>
        <SeparatorDiv />
        <ParcelInformation>
          <ParcelInfoTitle>{translations.parcel_information}</ParcelInfoTitle>
          <ParcelInfoLabelValue>
            {translations.total_no_of_parcels}: <ParcelInfoValue>1</ParcelInfoValue>{" "}
          </ParcelInfoLabelValue>
          <ParcelInfoLabelValue>
            {translations.weight}:{" "}
            <ParcelInfoValue>
              {order.package_order_data.weight}kg
            </ParcelInfoValue>{" "}
          </ParcelInfoLabelValue>
          <ParcelInfoLabelValue style={{ margin: 0 }}>
            {translations.parcel_dimensions}
          </ParcelInfoLabelValue>
          <ParcelInfoDimensionsContainer>
            <ParcelInfoLabelValue style={{ margin: 0 }}>
              W:{" "}
              <ParcelInfoValue>
                {order.package_order_data.width}cm
              </ParcelInfoValue>{" "}
            </ParcelInfoLabelValue>
            <ParcelInfoLabelValue style={{ margin: 0 }}>
              L:{" "}
              <ParcelInfoValue>
                {order.package_order_data.length}cm
              </ParcelInfoValue>{" "}
            </ParcelInfoLabelValue>
            <ParcelInfoLabelValue style={{ margin: 0 }}>
              H:{" "}
              <ParcelInfoValue>
                {order.package_order_data.height}cm
              </ParcelInfoValue>{" "}
            </ParcelInfoLabelValue>
          </ParcelInfoDimensionsContainer>
        </ParcelInformation>
        <SeparatorDiv />
        <PriceAndActionsContainer>
          <PriceAndActionsTextContainer>
            <TotalPriceText>{translations.total}: € {order.final_price}</TotalPriceText>
            <TotalPriceIncludesOtherCostsText>
              {translations.including_tax_and_other_charges}
            </TotalPriceIncludesOtherCostsText>
          </PriceAndActionsTextContainer>
          <ActionsContainer>
            <TrackOrderButton onClick={() => {
              const currentDomain = window.location.origin;
              const desiredRoute = `/track_package?tracking_number=${order.last_mily_id}`; 
              window.location.href = currentDomain + desiredRoute;
            }}>{translations.track_order}</TrackOrderButton>
          </ActionsContainer>
        </PriceAndActionsContainer>
      </OrderContent>
    </OrderComponent>
  );
};

export default Order;

const PriceAndActionsTextContainer = styled.div``;

const FontFamilyAndColor = css`
  font-family: "Inter";
  color: #173b5f;
`;

const TotalPriceText = styled.p`
  ${FontFamilyAndColor};
  font-weight: bolder;
  font-size: 2rem;
  text-align: right;
  margin: 0;
`;

const TotalPriceIncludesOtherCostsText = styled.p`
  ${FontFamilyAndColor};
  color: #073b62;
  text-align: right;
  margin-bottom: auto;
`;

const ActionsContainer = styled.div`
  margin-top: auto;
`;

const SharedActionButtonStyle = css`
  padding: 0.75rem 0rem;
  width: 10rem;
  border-radius: 0.6rem;
  font-family: "Inter";
  font-size: 0.9rem;
  text-align: center;
`;

const CancelOrderButton = styled.div`
  ${SharedActionButtonStyle};
  border: solid thin red;
  color: red;
  margin-right: 1rem;
`;

const TrackOrderButton = styled.div`
  ${SharedActionButtonStyle};
  background-color: #073b62;
  color: white;
  margin-left: auto;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const SubTitle = css`
  ${FontFamilyAndColor};
  font-weight: 700;
`;

const OrderComponent = styled.div`
  margin-top: 3rem;
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 16px rgba(7, 59, 98, 0.1);
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
`;

const OrderContent = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const OrderDetails = styled.div`
  width: 21%;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const OrderNumberAndDateContainer = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`;

const BasicValueDisplayContainer = styled.div``;

const BasicValueDisplayLabel = styled.p`
  ${SubTitle};
`;

const BasicValueDisplayValue = styled.p`
  ${FontFamilyAndColor};
`;

const OrderStatusLabel = styled.p`
  ${SubTitle};
`;

const OrderStatusStyled = styled.div`
  background-color: ${({ $background_color }) => $background_color};
  padding: 0.5rem 2rem;
  border-radius: 5px;
  width: fit-content;
`;

const OrderStatusText = styled.p`
  ${FontFamilyAndColor};
  margin: 0;
  color: white;
  text-transform: capitalize;
  font-size: 0.8rem;
`;

const AddressDisplay = styled.div`
  width: 22%;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const SeparatorDiv = styled.div`
  /* border-right: dotted thin black; */
  /* background: repeating-linear-gradient(to bottom, rgba(0,0,0,0.5) 0,
    rgba(0,0,0,0.5) 3px, rgba(255,255,255,0) 3px,
    rgba(255,255,255,0) 10px);
    display: block;
    width: 3px; // incase we want custom dashes */
  border: dashed thin #e6e6e6;
`;

const OrderDetailsBasicValue = styled(BasicValueDisplay)``;

const ParcelInformation = styled.div`
  width: 24%;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const ParcelInfoTitle = styled.p`
  ${FontFamilyAndColor};
  font-size: 1.1rem;
  color: #161616;
  font-weight: 700;
`;

const ParcelInfoLabelValue = styled.p`
  ${FontFamilyAndColor};
  color: #073b62;
`;

const ParcelInfoValue = styled.span`
  font-weight: 700;
`;

const ParcelInfoDimensionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 0.5rem;
`;

const PriceAndActionsContainer = styled.div`
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
  @media (max-width: 768px) {
    width: auto;
    justify-content: none;
    align-items: start;
  }
`;
