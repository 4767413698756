import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Order from "./Order";
import PrintButton from "./PrintButton";

const ViewOrders = ({ orders, translations }) => {
  const ordersRef = useRef();
  return (
    <>
      <PrintButton translations={translations} componentRef={ordersRef} />
      <ViewOrdersComponent>
        <ViewOrdersContent ref={ordersRef}>
          <OrdersContainer>
            {orders.map((order) => (
              <Order translations={translations} order={order} />
            ))}
          </OrdersContainer>
        </ViewOrdersContent>
      </ViewOrdersComponent>
    </>
  );
};

export default ViewOrders;

const ViewOrdersComponent = styled.div`
  width: 90%;
  @media (max-width: 768px) {
    width: 90%;
  }
  margin: auto;
  margin-top: 3rem;
`;

const ViewOrdersContent = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`;

const OrdersContainer = styled.div`
  width: 95%;
  margin: 1rem auto;
`;
