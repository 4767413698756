import React, { useState } from "react";
import PersonalStep from "./PersonalStep";
import AddressStep from "./AddressStep";
import FinalStep from "./FinalStep";
import { sign_in_path } from "../../../routes";

const testInitialValues = {
  firstname: "test",
  surname: "test",
  phone: "1234567891",
  street: "-1",
  unit: "-1",
  country: "fake",
  city: "fake",
  state: "fake",
  postalcode: "fake",
};

function IndividualSignUpPage() {
  const initialValues = {
    firstname: "",
    surname: "",
    phone: null,
    street: "",
    unit: "",
    country: "",
    city: "",
    state: "",
    postalcode: "",
    facebook: "",
    instagram: "",
  };
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isContinue, setIsContinue] = useState(0);

  // Proceed to next step
  const nextStep = (e) => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = (e) => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleChangeManual(name, value);
  };

  const handleChangeManual = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    validation(name, value);
  };

  const validation = (name, value) => {
    //First Name Validation
    if (name == "firstname") {
      if (value == "") {
        setErrors({ ...errors, [name]: "First Name is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //Sur Name Validation
    if (name == "surname") {
      if (value != "") {
        setErrors({ ...errors, [name]: null });
      } else {
        setErrors({ ...errors, [name]: "Surname is Required" });
      }
    }

    //Phone number Validation
    if (name == "phone") {
      if (value == "") {
        setErrors({ ...errors, [name]: "Phone is required" });
      } else if (value.length != 10) {
        setErrors({ ...errors, [name]: "Phone number must be 10 digits" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //Street Validation
    if (name == "street") {
      if (value == "") {
        setErrors({ ...errors, [name]: "Street is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //Country Validation
    if (name == "country") {
      if (value == "") {
        setErrors({ ...errors, [name]: "Country is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //City Validation
    if (name == "city") {
      if (value == "") {
        setErrors({ ...errors, [name]: "City is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //State Validation
    if (name == "state") {
      if (value == "") {
        setErrors({ ...errors, [name]: "State is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }

    //Postal Code Validation
    if (name == "postalcode") {
      if (value == "") {
        setErrors({ ...errors, [name]: "Postal code is required" });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }
  };

  switch (step) {
    case 1:
      return (
        <div class="container-signup-individual">
          <div class="sub-container-signup-individual">
            <h1 class="create-an-account">Create an Account</h1>
            <p class="title-personal-info">Provide your personal information</p>
            <div className="step-1-status-signin-individual">
              <div>1. Personal</div>
              <div>2. Address</div>
              <div>3. Final Step</div>
            </div>
            <PersonalStep
              nextStep={nextStep}
              handleChange={handleChange}
              formErrors={errors}
              setErrors={setErrors}
              values={formValues}
              isContinue={isContinue}
              setIsContinue={setIsContinue}
            />
            <p className="account-exists">
              Already have an account?{" "}
              <span>
                <a href={sign_in_path()}>Sign in</a>
              </span>
            </p>
          </div>
        </div>
      );
    case 2:
      return (
        <div class="container-signup-individual">
          <div class="sub-container-signup-individual">
            <h1 class="create-an-account">Create an Account</h1>
            <p class="title-personal-info">Provide your personal information</p>
            <div className="step-2-status-signin-individual">
              <div>1. Personal</div>
              <div>2. Address</div>
              <div>3. Final Step</div>
            </div>
            <AddressStep
              nextStep={nextStep}
              prevStep={prevStep}
              handleChange={handleChange}
              handleChangeManual={handleChangeManual}
              formErrors={errors}
              setErrors={setErrors}
              setFormValues={setFormValues}
              values={formValues}
              isContinue={isContinue}
              setIsContinue={setIsContinue}
            />
            <p class="account-exists">
              Already have an account?{" "}
              <span>
                <a href={sign_in_path()}>Sign in</a>
              </span>
            </p>
          </div>
        </div>
      );
    case 3:
      return (
        <div class="container-signup-individual">
          <div class="sub-container-signup-individual">
            <h1 class="create-an-account">Create an Account</h1>
            <p class="title-personal-info">Provide your personal information</p>
            <div className="step-3-status-signin-individual">
              <div>1. Personal</div>
              <div>2. Address</div>
              <div>3. Final Step</div>
            </div>
            <FinalStep
              prevStep={prevStep}
              handleChange={handleChange}
              formErrors={errors}
              setErrors={setErrors}
              values={formValues}
              isContinue={isContinue}
              setIsContinue={setIsContinue}
            />
            <p class="account-exists">
              Already have an account?{" "}
              <span>
                <a href={sign_in_path()}>Sign in</a>
              </span>
            </p>
          </div>
        </div>
      );
  }
}

export default IndividualSignUpPage;
