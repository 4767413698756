import React, { Component, useEffect } from "react";
import { useState } from "react";

const defaultValueForinputFieldsToTestEasier = { weight: "10", length: "10", width: "14", height: "17" };

const emptyValuesForInputFields = { weight: "", length: "", width: "", height: "" };

const PackageInfoForm = ({width, height, length, weight, notice}) => {
  console.log(notice);
  const [inputFields, setInputFields] = useState([
    emptyValuesForInputFields
  ]);

  useEffect(() => {
    const emptyInputFieldsWithProps = inputFields[0];
    if (width) emptyInputFieldsWithProps.width = width;
    if (height) emptyInputFieldsWithProps.height = height;
    if (length) emptyInputFieldsWithProps.length = length;
    if (weight) emptyInputFieldsWithProps.weight = weight;
    setInputFields([emptyInputFieldsWithProps]);
  }, []);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { weight: "", length: "", width: "", height: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      {inputFields.map((input, index) => {
        return (
          <div key={index} className="parcel-info-div">
            <div className="sub-div-1-parcel-info-div">
              <p>#{index + 1}</p>
              <span className="span-1-sub-div-1">Weight:</span>
              <input
                name="weight"
                type="number"
                value={input.weight}
                className="input-parcel-info"
                onChange={(event) => handleFormChange(index, event)}
                style={{ borderColor: (notice && !input.weight) && "red"}}
              ></input>
              <span className="span-1-sub-div-2">Kg</span>
            </div>
            <div className="sub-div-2-parcel-info-div">
              <span>Length:</span>
              <input
                name="length"
                type="number"
                value={input.length}
                className="input-parcel-info"
                onChange={(event) => handleFormChange(index, event)}
              ></input>
              <span>cm</span>

              <span>Width:</span>
              <input
                name="width"
                type="number"
                value={input.width}
                className="input-parcel-info"
                onChange={(event) => handleFormChange(index, event)}
              ></input>
              <span>cm</span>

              <span>Height:</span>
              <input
                name="height"
                type="number"
                value={input.height}
                className="input-parcel-info"
                onChange={(event) => handleFormChange(index, event)}
              ></input>
              <span>cm</span>
              <div className="unit-for-small-screen input-parcel-info">cm</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PackageInfoForm;
