import React from "react";
import styled from 'styled-components';

const SelectStyled = styled.select`
  background-color: white;
  padding: 0.1rem;
  box-shadow: none;
  border: solid black thin;
  border-radius: 0.25rem;
  font-size: 0.7rem;
`;

const StyledOption = styled.option`

`;

const LanguageSelector = ({ current_language }) => {
  const changeLanguage = (event) => {
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('locale', event.target.value === "en" ? "en" : "el");
    window.location.href = currentUrl.toString();
  }

  return (
    <>
      <SelectStyled aria-label="Language Selector" onChange={changeLanguage}>
        <StyledOption value={"el"} selected={"el" === current_language}>
          Ελληνικά
        </StyledOption>
        <StyledOption value={"en"} selected={"en" === current_language}>
          English
        </StyledOption>
      </SelectStyled>
    </>
  );
};

export default LanguageSelector;
