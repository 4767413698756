import React, { Component } from 'react';
import { signUpUser } from '../sign_up_util';

export class FinalStep extends Component {
  continue = async e => {
    e.preventDefault();
    const error = await signUpUser(this.props.values, "1");
    console.log(error);
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <div className='form-signup-individual'>
        <label className='label-signup-individual'>Enter Business Facebook Account Link (If Any)</label>
        <input onChange={handleChange('facebookLink')} type="text" defaultValue={values.facebookLink} placeholder="Paste Link Here" className='input-signin-individual'></input>
        <label className='label-signup-individual'>Enter Business Instagram Account Link (If Any)</label>
        <input onChange={handleChange('instagramLink')} type="text" defaultValue={values.instagramLink} placeholder="Paste Link Here" className='input-signin-individual'></input>
        <div className='div-buttons-signin-individual'>
            <button onClick={this.back} className="back-button-signup-individual">Back</button>
            <button onClick={this.continue} className="next-button-signup-individual">Next</button>
        </div>
      </div>
    );
  }
}

export default FinalStep;
