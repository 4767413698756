import React, { useEffect } from "react";
import Autocomplete from "react-google-autocomplete";

const GOOGLE_MAPS_API = "AIzaSyB5-5oP3So_gdqwcy2450ojUeYAPAN_vDs";

function AddressStep({
  nextStep,
  prevStep,
  handleChange,
  handleChangeManual,
  formErrors,
  setErrors,
  values,
  isContinue,
  setIsContinue,
  setFormValues
}) {
  const continues = (e) => {
    e.preventDefault();
    setIsContinue(2);
    setErrors(validate());
  };

  const back = (e) => {
    e.preventDefault();
    setIsContinue(0);
    prevStep();
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isContinue == 2) {
      nextStep();
    }
  }, [formErrors]);

  const validate = () => {
    const errors = {};
    if (!values.street) {
      errors.street = "Street is required!";
    }
    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.state) {
      errors.state = "State is required!";
    }
    if (!values.postalcode) {
      errors.postalcode = "Postal Code is required!";
    }
    return errors;
  };

  const onPlaceSelected = (place) => {
    let street = undefined;
    let unitNumber = undefined;
    let country = undefined;
    let city = undefined;
    let state = undefined;
    let postalCode = undefined;
    place.address_components.forEach((address_component) => {
      if (address_component.types.find((type) => type === "route")) {
        street = address_component.long_name;
      }
      if (address_component.types.find((type) => type === "country")) {
        country = address_component.long_name;
      }
      if (address_component.types.find((type) => type === "street_number")) {
        unitNumber = address_component.long_name;
      }
      if (address_component.types.find((type) => type === "locality")) {
        city = address_component.long_name;
      }
      if (
        address_component.types.find((type) => type === "administrative_area_level_1")
      ) {
        state = address_component.long_name;
      }
      if (address_component.types.find((type) => type === "postal_code")) {
        postalCode = address_component.long_name;
      }
      if (address_component.types.find((type) => type === "country")) {
        country = address_component.long_name;
      }
    });
    setFormValues({
      ...values,
      ...(unitNumber ? { unit: unitNumber } : {}),
      ...(street ? { street: street } : {}),
      ...(country ? { country: country } : {}),
      ...(city ? { city: city } : {}),
      ...(state ? { state: state } : {}),
      ...(postalCode ? { postalcode: postalCode } : {}),
    })
  };

  return (
    <div className="form-signup-individual">
      <label className="label-signup-individual">
        Enter your address to auto fill results. However verify results before
        continuing.
      </label>
      <Autocomplete
        apiKey={GOOGLE_MAPS_API}
        options={{ types: ["address"] }}
        className={"input-signin-individual"}
        onPlaceSelected={(place) => {
          onPlaceSelected(place);
        }}
        placeholder="Enter location"
      />

      <label className="label-signup-individual">Street</label>
      <input
        name="street"
        onChange={handleChange}
        type="text"
        value={values.street}
        placeholder="Enter Your Street"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.street}</span>

      <label className="label-signup-individual">Unit Number (Optional)</label>
      <input
        name="unit"
        onChange={handleChange}
        type="text"
        value={values.unit}
        placeholder="Enter Your Unit Number"
        className="input-signin-individual"
      ></input>
      <span className="error-message"></span>

      <label className="label-signup-individual">Country</label>
      <input
        name="country"
        onChange={handleChange}
        type="text"
        value={values.country}
        placeholder="Enter Country"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.country}</span>

      <label className="label-signup-individual">City</label>
      <input
        name="city"
        onChange={handleChange}
        type="text"
        value={values.city}
        placeholder="Enter City"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.city}</span>

      <label className="label-signup-individual">State</label>
      <input
        name="state"
        onChange={handleChange}
        type="text"
        value={values.state}
        placeholder="Enter State"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.state}</span>

      <label className="label-signup-individual">Postal Code</label>
      <input
        name="postalcode"
        onChange={handleChange}
        type="text"
        value={values.postalcode}
        placeholder="Enter Postal Code"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.postalcode}</span>

      <div className="div-buttons-signin-individual">
        <button onClick={back} className="back-button-signup-individual">
          Back
        </button>
        <button
          onClick={(e) => continues(e)}
          className="next-button-signup-individual"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AddressStep;
