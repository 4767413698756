import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styled from "styled-components";

const ButtonStyle = styled.div`
  padding: 0.75rem 0rem;
  width: 10rem;
  border-radius: 0.6rem;
  font-family: "Inter";
  font-size: 0.9rem;
  text-align: center;
  background-color: #073b62;
  color: white;
  margin-left: auto;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: -2rem; // TODO bad css
`;

function PrintButton({ componentRef, translations }) {
  const handlePrint = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the ratio for the canvas data to fit within PDF page
      const imgWidth = 210; // Width of A4 in mm
      const pageHeight = 295; // Height of A4 in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF("p", "mm", "a4");
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add additional pages if the content overflows
      while (heightLeft >= 20) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("orders.pdf");
    });
  };

  return <ButtonStyle onClick={handlePrint}>{translations.print_to_pdf}</ButtonStyle>;
}

export default PrintButton;
