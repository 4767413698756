import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { convertStringToNumberSafe } from "../util/convertData";

const XPSlider = ({ label, inputNameToAssignData }) => {
  const [value, setValue] = useState(0);
  const handleOnChange = (value) => {
    setValue(value * 50);
    if (value === 100) {
      setValue("5000 or more");
    }
  };
  return (
    <div class="slider-container">
      <div class="slider-label inter-medium-white-14px">
        <p>{label}</p>
      </div>

      <input
        value={convertStringToNumberSafe(value)}
        style={{ display: "none" }}
        type="number"
        name={inputNameToAssignData}
        aria-label={label}
      />

      <div class="slider-label-value inter-medium-white-14px">{value}</div>
      <Slider
        handleStyle={{
          borderColor: "transparent",
          height: 25,
          width: 25,
          backgroundColor: "black",
        }}
        trackStyle={{ backgroundColor: "#d6b553", height: 15 }}
        railStyle={{ height: 15 }}
        onChange={handleOnChange}
        className="slider"
      />
    </div>
  );
};

export default XPSlider;
