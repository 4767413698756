import React, { useEffect } from "react";
import {profile_setup_path} from '../../../routes'

function PersonalStep({
  nextStep,
  handleChange,
  formErrors,
  setErrors,
  values,
  isContinue,
  setIsContinue,
}) {
  const continues = (e) => {
    e.preventDefault();
    setIsContinue(1);
    setErrors(validate());
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isContinue == 1) {
      nextStep();
    }
  }, [formErrors]);

  const validate = () => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.firstname = "First name is required!";
    }
    if (!values.surname) {
      errors.surname = "Surname is required!";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
    } else if (values.phone.length != 10) {
      errors.phone = "Phone number must be 10 digits!";
    }
    return errors;
  };

  return (
    <div className="form-signup-individual">
      <label className="label-signup-individual">First Name</label>
      <input
        name="firstname"
        onChange={handleChange}
        type="text"
        defaultValue={values.firstname}
        placeholder="Enter Your First Name"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.firstname}</span>

      <label className="label-signup-individual">Surname</label>
      <input
        name="surname"
        onChange={handleChange}
        type="text"
        defaultValue={values.surname}
        placeholder="Enter Your Surname"
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.surname}</span>

      <label className="label-signup-individual">Phone Number</label>
      <input
        name="phone"
        onChange={handleChange}
        type="text"
        placeholder="Enter Your Phone Number"
        defaultValue={values.phone}
        className="input-signin-individual"
      ></input>
      <span className="error-message">{formErrors.phone}</span>

      <div className="div-buttons-signin-individual">
        <a href={profile_setup_path()} className="a-button-signup-individual">
          <p>Back</p>
        </a>
        <button
          onClick={(e) => continues(e)}
          className="next-button-signup-individual"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PersonalStep;
