import React, { Component } from 'react';

export class BusinessStep extends Component {
    continue = e => {
        const { values, setErrors, errors } = this.props;
        e.preventDefault();
        if (!this.props.values.business_name) {
            setErrors({ ...errors, business_name: "Business name is mandatory." })
        }
        else {
            setErrors({});
            this.props.nextStep();
        }
    };
    
    render() {
    const { values, handleChange } = this.props;
    return (
        <div className='form-signup-individual'>
            <label className='label-signup-individual'>Business Name</label>
            <input onChange={handleChange('business_name')} type="text" defaultValue={values.business_name} placeholder="Enter Your Business Name" className='input-signin-individual'></input>
            <label className='label-signup-individual'>Business Email</label>
            <input onChange={handleChange('business_email')} type="email" defaultValue={values.business_email} placeholder="Enter Your Business Email" className='input-signin-individual'></input>
            <label className='label-signup-individual'>Registration Number</label>
            <input onChange={handleChange('registration_number')} type="text" defaultValue={values.registration_number} placeholder="Enter Registration Number" className='input-signin-individual'></input>
            <label className='label-signup-individual'>Phone Number</label>
            <input onChange={handleChange('phone')} type="number" defaultValue={values.phone_number} className='input-signin-individual'></input>
            <label className='label-signup-individual'>VAT Number</label>
            <input onChange={handleChange('vat_number')} type="text" defaultValue={values.vat_number} placeholder="Enter VAT Number" className='input-signin-individual'></input>
            <div className='div-buttons-signin-individual'>
                <a href='/sign_up' className="a-button-signup-individual">
                    <p>Back</p></a>
                <button onClick={this.continue} className="next-button-signup-individual">Next</button>
            </div>
        </div>
    );
    }
}

export default BusinessStep;
