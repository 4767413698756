import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const GoogleAutoComplete = ({ notice, name, address_string_name ,className, placeholder, defaultValue, lon_lat_name, name_wrapper }) => {
  const [placeObject, setPlaceObject] = useState(undefined);
  const [showError, setShowError] = useState(notice && !defaultValue)
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyB5-5oP3So_gdqwcy2450ojUeYAPAN_vDs",
  });


  // Use effects are for when there is an error and we need to turn the address string into a address object
  // TODO there is some loading involved so theoretically there could be an error if someone clicked too fast
  useEffect(() => {
    if (defaultValue) {
      getPlacePredictions({ input: defaultValue });
    }
  }, []);

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          formatAddressData(placeDetails)
        }
      );
  }, [placePredictions]);

  const formatAddressData = (addressData) => {
    const placeFormatted = {
      formatted_address: addressData.formatted_address,
      address_components: addressData.address_components,
      lat: addressData.geometry.location.lat(),
      lon: addressData.geometry.location.lng(),
    }
    setPlaceObject(placeFormatted);
  }


  const nameToUse = name_wrapper ? `${name_wrapper}[${name}]` : name;
  const addressStringNameToUse = name_wrapper ? `${name_wrapper}[${address_string_name}]` : address_string_name;
  const lonNameToUse = name_wrapper ? `${name_wrapper}[${lon_lat_name}_lon]` : lon_lat_name + "_lon"
  const latNameToUse = name_wrapper ? `${name_wrapper}[${lon_lat_name}_lat]` : lon_lat_name + "_lat"

  return (
    <>
      {name && <input style={{ display: "none", position: "absolute" }} name={nameToUse} value={JSON.stringify(placeObject)} />}
      {!placeObject && defaultValue && <input style={{ display: "none", position: "absolute" }} name={addressStringNameToUse} value={defaultValue} />}
      {placeObject && <input style={{ display: "none", position: "absolute" }} name={addressStringNameToUse} value={placeObject.formatted_address} />}
      {lon_lat_name && placeObject && <input style={{ display: "none", position: "absolute" }} name={lonNameToUse} value={placeObject.lon} />}
      {lon_lat_name && placeObject && <input style={{ display: "none", position: "absolute" }} name={latNameToUse} value={placeObject.lat} />}
      <Autocomplete
        options={{ types: [] }}
        apiKey={"AIzaSyB5-5oP3So_gdqwcy2450ojUeYAPAN_vDs"}
        className={className}
        placeholder={placeholder}
        defaultChecked={true}
        defaultValue={defaultValue}
        style={{ borderColor: (showError) && "red"}}
        onChange={() => setShowError(false)}
        onPlaceSelected={(place) => {
          formatAddressData(place);
        }}
      />
    </>
  );
};

export default GoogleAutoComplete;
